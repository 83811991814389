/* apply a natural box layout model to all elements, but allowing components to change
http://www.paulirish.com/2012/box-sizing-border-box-ftw/; 
*/
:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

/* Set full height: http://stackoverflow.com/questions/6654958/make-body-have-100-of-the-browser-height */
html {
  /* body will set it's height based on its parent, which is html */
  height: 100%;

  /* set full width as well */
  width: 100%;
}
body {
  /* min-height is needed for pages that might scroll, ie they may contain _more_ than 100% of viewport height */
  min-height: 100%;

  /* needed to prevent unwanted scroll-bars */
  margin: 0;
  padding: 0;
  background-color: #f5fafa;
}

@layer base {
  .surveyConditions__container {
    @apply mb-8 rounded-lg text-white z-10 pointer-events-none opacity-0 w-0  flex flex-col overflow-hidden;
    transition: 0.25s;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 8px 1px inset rgba(0, 0, 0, 0.15);
  }

  .surveyConditions__container--visible {
    @apply mx-8 opacity-100 pointer-events-auto sticky top-10 pb-8 max-h-screen overflow-y-scroll;
    width: 20rem;
  }

  .surveyConditions__header {
    @apply border-b border-gray-900 text-white font-semibold text-center flex justify-between p-2;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .surveyConditions__heading {
    @apply text-base;
  }

  .surveyConditions__body {
    @apply overflow-hidden flex flex-col mt-4;
  }

  .surveyCondition {
    @apply rounded mb-4 text-gray-900 py-2 m-2;
    background-color: rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 2px 1px inset rgba(0, 0, 0, 0.25);
  }
  .surveyCondition__body {
    @apply p-1 px-2;
  }
  .surveyCondition__actions {
    @apply flex justify-between py-2 rounded-t border-b;
  }
  .block-button {
    @apply block p-4 text-center rounded flex flex-row justify-center items-center shadow-inner text-gray-900 text-xl;
    background-color: rgba(0, 0, 100, 0.03);
  }
  .block-button {
    @apply no-underline text-blue-700;
  }
  .move-button {
    @apply block m-12 p-12 text-center rounded w-2/3 text-3xl shadow-md;
  }
  .cancel-button {
    @apply font-bold py-2 px-4 rounded;
    position: absolute;
    top: 250px;
    left: 275px;
  }
  .panelEditor__button {
    @apply opacity-25 ml-2;
  }
  /* TODO MAKE WORK -EDB .panelEditor__button {
    @apply opacity-25 transition-fast transition-property-opacity ml-2;
  } */
  .panelEditor__button:hover {
    @apply opacity-100;
  }
  .panelEditor__button--sorts {
    @apply m-0;
  }

  .ul-list ul li:hover {
    background-color: #eee;
  }

  .float-right {
    float: right;
  }

  .ul-list ul li {
    border: 1px solid #ddd;
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
    position: relative;
  }
  .grayout {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.9;
    z-index: 9999;
  }
  .promptIcon {
    @apply absolute  z-10 bg-white rounded-full p-3 border border-gray-300 shadow;
    transform: translate3d(-3.5rem, 0, 0);
  }
  .surveyBuilderNav__link {
    @apply text-gray-500 text-lg p-2 px-3 rounded inline-flex items-center mx-2 no-underline;
  }
  .surveyBuilderNav__link svg {
    @apply mr-2;
  }
  .surveyBuilderNav__link:hover {
    @apply bg-transparent no-underline text-white;
  }
  .conditionalTargetSwitch {
    /* @apply border border-green rounded absolute bg-white;
    transform: translate3d(-3.5rem, 0, 0); */
  }
  .prompt__saveIndicator {
    @apply absolute;
    top: 0.75rem;
    right: 0.5rem;
  }
  .promptOption__saveIndicator {
    @apply absolute;
    top: 0.35rem;
    right: 0.25rem;
  }
  .editable-input {
    background-color: transparent;
    outline: none;
  }
}
